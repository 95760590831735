import React from 'react'
import "./ImpactguruSection4.scss"
import heart from "./images/Group7960.png"
import kid from "./images/Group7977.png"
import covid from "./images/Group7892.png"
import dna from "./images/Group7955.png"
import note from "./images/Group7966.png"
import call from "./images/Group7968.png"
import icu from "./images/Group 8353.png"
import dn2 from "./images/Group 8378.png"
import "../carepalTreatment/treatment.scss"
import Carousel from 'react-bootstrap/Carousel';



const ImpactGuruSection4 = () => {

   

  return (
    <>
    <div className='container-fluid colorbg  d-sm-none d-md-block ' id="web-section4-corsol">
         <div className='header-content-section4 px-24'style={{textAlign:"center"}}>Crowdfunding Categories</div>
        <div className='row-flex '>
           
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img className='icons' src={heart}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Organ Transplant</div>
           </div>
           </div>
          
        </div>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img className='icons' src={kid}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Child Health</div>
           </div>
           </div>
           
        </div>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img className='icons'  src={covid}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Cancer Treatments</div>
           </div>
           </div>
           
        </div>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img  className='icons'  src={dna}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Spinal Muscular Atrophy</div>
           </div>
           </div>
         
        </div>
        </div>
        <div className='row-flex '>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img  className='icons'  src={note}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Medical Emergency</div>
           </div>
           </div>
           
        </div>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img className='icons'  src={call}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Accident & Injury</div>
           </div>
           </div>
          
        </div>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img  className='icons'  src={icu}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>ICU Support</div>
           </div>
           </div>
         
        </div>
        <div className='card-test-impact'>
           <div className='card-top-part-impact'>
            <img  className='icons'  src={dn2}/>
            <div className='card-bottom-part-impact'>
                      <div className='text-categ-box'>Rare Diseases</div>
           </div>
           </div>
        
        </div>
        </div>
      
    </div>
  
    <div className='d-lg-none  d-md-none d-lg-block' id="mobile-treatment-card-mobile" style={{ backgroundColor: "#1E75BB ", paddingTop: "30px", paddingBottom: "30px" }}>
            <div className='heading-treatment headindtext-second-money' style={{ textAlign: "center",color: "#FFFFFF !important" }}>Crowdfunding Categories</div>
            <Carousel className='d-lg-none d-xl-block d-md-none d-lg-block mt-4 mobile-croud' prevIcon={false} nextIcon={false}  >
               <Carousel.Item interval={1000}>
                  <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr'src={heart} />
                           <div className='card-bottom-part-money '>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Organ Transplant</strong></div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={kid}  />
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Child Health</strong></div>
                           </div>
                        </div>

                     </div>
                  </div>

               </Carousel.Item>
               <Carousel.Item interval={500}>
               <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={covid}/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Cancer Treatments</strong></div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={dna} />
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Spinal Muscular Atrophy</strong></div>
                           </div>
                        </div>

                     </div>
                  </div>

               </Carousel.Item>
               <Carousel.Item>
               <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={note}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Medical Emergency</strong></div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={call}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Accident & Injury</strong></div>
                           </div>
                        </div>

                     </div>
                  </div>
               </Carousel.Item>
               <Carousel.Item>
               <div className='card-position-flex pb-3'>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={icu}  alt=""/>
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>ICU Support</strong></div>
                           </div>
                        </div>

                     </div>
                     <div className='card-test-money-mobile card-position-flex-card'>
                        <div className='card-top-part-money'>
                           <img className='icons-rr' src={dn2}  alt="" />
                           <div className='card-bottom-part-money'>
                              <div className='card-text-treatment-list botum-bg-color'><strong>Rare Diseases</strong></div>
                           </div>
                        </div>

                     </div>
                  </div>
               </Carousel.Item>
            </Carousel>
         </div>
    </>
  )
}

export default ImpactGuruSection4