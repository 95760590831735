import React from 'react';
import AboutUs from '../../Pages/About/AboutUs';
import { Container, Row, Col } from 'react-bootstrap';
import logo from "./carepallogo-svg.png"

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import "./header.scss"

function Footer() {

  const hideNavSlider = () => {
    document.querySelector("#offcanvasNavbar-expand-sm") && document.querySelector("#offcanvasNavbar-expand-sm").querySelector(".btn-close") && document.querySelector("#offcanvasNavbar-expand-sm").querySelector(".btn-close").click()
  }

  return (
    <div className='c-nav'>
      <Container>
        <Row>
          <Col span={12}>
            {['sm'].map((expand) => (
              <Navbar key={expand} expand={expand} sticky="top">
                <Navbar.Brand href="/"><img src={logo}  alt=""/></Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                      <Navbar.Brand href="/"><img src={logo} className="logo" alt=""/></Navbar.Brand>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <Link to="/" className='nav-link nav-l-shadow aboutus' onClick={hideNavSlider}>Home </Link>

                      <NavDropdown
                        title="Our Business"
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                        className="aboutus"
                      >
                        <div className='navdropdown'>
                        <Link to="/impactguru" onClick={hideNavSlider} className='nav-link mr-0 nav-l-shadow aboutus'>ImpactGuru</Link>
                        <Link to="/carpalmoney" onClick={hideNavSlider} className='nav-link mr-0 nav-l-shadow aboutus'>CarePal Money</Link>
                        <Link to="/carepalsecure" onClick={hideNavSlider} className='nav-link mr-0 nav-l-shadow aboutus'>CarePal Secure</Link>
                        </div>
                        
                      </NavDropdown>
                      <Link to="/about-us" onClick={hideNavSlider} className='nav-link aboutus'>About Us</Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            ))}
          </Col>
        </Row>
      </Container>
    </div>

  );
}

export default Footer;

//
