import React from 'react';
import "./testimonial.scss";
import Carousel from 'react-bootstrap/Carousel';
import quets from "./images/format_quot.png"
const Testimonials = () => {
  return (
    <div className='py-5' style={{ background: "#EDF8FD" }}>
      <div className='container-fluid testimonialBg' >


        <div className='container' >
          <div className='testimonial-small-header'>Testimonials  </div>
          <div className='testimonial-small-header-second mt-3'>Our Motivation To Do <br /> What We Do</div>

          <div className='row-flex hideInMobile' >
            <div className='card-test'>
              <div className='card-top-part'>The best part about availing loans through CarePal Money was that they offered customised schemes. I was able to choose a plan that was best suited to my financial resources. The EMI plan was quite pocket-friendly. At every step, the executives were of great help.</div>
              <div className='card-bottom-part'>
                <div>
                  <div>Rakesh Parab</div>

                </div>
                <div className='quate-design'>
                  <img style={{ width: "71px" }} src={quets}  alt=""/>
                </div>
              </div>
            </div>
            <div className='card-test'>
              <div className='card-top-part'>I will forever be grateful to ImpactGuru and its kind donors. Without their efforts and generosity, I cannot even begin to think what would have happened to my Dipika. I truly appreciate the timely help that I received through crowdfunding on ImpactGuru.</div>
              <div className='card-bottom-part'>
                <div>
                  <div>Rita Vaswani</div>

                </div>
                <div className='quate-design'>
                  <img style={{ width: "71px" }} src={quets}  alt=""/>
                </div>
              </div>
            </div>
            <div className='card-test' >
              <div className='card-top-part'>When we needed funds for my father’s treatment, we received help from crowdfunding on ImpactGuru. We even received discounts on medicines, diagnostic tests and teleconsultation services with the help of CarePal Secure.</div>
              <div className='card-bottom-part'>
                <div>
                  <div>Namrata Verma</div>

                </div>
                <div className='quate-design'>
                  <img style={{ width: "71px" }} src={quets}  alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='mobile-testimonial' className='testimonialBg mt-4 mobile-testimonial'>
        <Carousel prevIcon={false} nextIcon={false}>
          <Carousel.Item interval={1000}  >
            <div className='card-test-mobile' >
              <div className='card-top-part'>The best part about availing loans through CarePal Money was that they offered customised schemes. I was able to choose a plan that was best suited to my financial resources. The EMI plan was quite pocket-friendly. At every step, the executives were of great help.</div>
              <div className='card-bottom-part'>
                <div>
                  <div>Rakesh Parab</div>
                  
                </div>
                <div>
                <img style={{ width: "71px" }} src={quets}  alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <div className='card-test-mobile' >
              <div className='card-top-part'>I will forever be grateful to ImpactGuru and its kind donors. Without their efforts and generosity, I cannot even begin to think what would have happened to my Dipika. I truly appreciate the timely help that I received through crowdfunding on ImpactGuru.</div>
              <div className='card-bottom-part'>
                <div>
                  <div>Rita Vaswani</div>
           
                </div>
                <div>
                <img style={{ width: "71px" }} src={quets}  alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <div className='card-test-mobile' >
              <div className='card-top-part'>When we needed funds for my father’s treatment, we received help from crowdfunding on ImpactGuru. We even received discounts on medicines, diagnostic tests and teleconsultation services with the help of CarePal Secure.</div>
              <div className='card-bottom-part'>
                <div>
                  <div>Namrata Verma</div>
          
                </div>
                <div>
                <img style={{ width: "71px" }} src={quets}   alt=""/>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  )
}

export default Testimonials