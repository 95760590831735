import React from 'react';
import "./Secondsection.scss";
import agedpic from "./images/Mask Group 173.png";
import doctor from "./images/Mask Group 174.png";
import dots from "./images/Group 7693.svg";
import circle from "../../Pages/images/circle.png";
import solid from "../../Pages/images/solid.png";
import hollow from "../../Pages/images/hollow.png";
import rectangle from "../../Pages/images/rectangle.png";



const SecondSection = () => {
  return (
    <div className='container margine-top-upper-bottom' style={{padingBottum:"73px"}}>
        <div className='row'>
             <div className='col-lg-6  col-12  position-relative home-section-images' >
                <img className="pic-second-home-top" src={agedpic}  alt=""/>
                <img src={solid} className="solidage" />
                <img src={circle} className="circleage"/>
                <img className='pic-second-home-bottom' src={doctor}  alt=""/>
                <img className='dots-black-mesh' src={dots}  alt=""/>
                <img src={rectangle} className="card-shadow"  alt=""/> 
             </div>
             <div className='col-lg-6  col-12 '>

             <div className='header-section-first mb-3'>The Need For CarePal</div>
                 <div className='header-paragrap-home'>In India, a major healthcare financing challenge dominates, owing to the high out-of-pocket expenses (OOPE) which total up to over 3 times the global average! Currently, the high OOPE related to healthcare is impoverishing around 55 million Indians annually, with over 17% of households incurring catastrophic levels of health expenditures every year. In addition, 65% to 70% of the middle-class population in India do not have healthcare insurance, while most of the remaining 30-35% are underinsured.<br/>
                  <br/>
                  Amidst this, a major question arises. <strong> How can one bridge this particular accessibility gap in the healthcare system? </strong> Diving deep into various healthcare financing models and innovative solutions, the <strong> ‘CarePal Group’</strong>  - <strong> <i>India’s integrated healthcare finance ecosystem,</i></strong> aims to bring together three healthcare financing solutions: Crowdfunding, Lending and Health Assurance, under one umbrella. <br/>
                  <br/>
                  CarePal Group (CarePal) encompasses three business entities that function independently yet serve the common purpose of bridging the gap between accessible and affordable healthcare finance for millions of patients in India. These three independent entities under CarePal Group are namely -</div>
                  <div className='d-none d-md-block'>
                    <br/>
                    <ul className='header-paragrap-home-ul-li'>
                         <li>ImpactGuru (www.impactguru.com) - India’s leading online medical fundraising platform.</li>
                         <li>CarePal Money (www.carepalmoney.com) - affordable healthcare lending marketplace. </li>
                         <li>CarePal Secure (www.carepalsecure.com) - new age healthcare protection marketplace.</li>
                    </ul>
                  </div>
             </div>
        </div>

    </div>
  )
}

export default SecondSection