import React from 'react';
import FirstSection from '../../component/FirstSection/FirstSection';
import SecondSection from '../../component/SecondSection/SecondSection';
import OurJoury from '../../component/OurJourny/OurJoury';
import Testimonials from '../../component/Testimonials/Testimonials';
import { LazyLoadImage, trackWindowScroll  } from 'react-lazy-load-image-component';

const Home = () => {
  return (
    <>
 
           <FirstSection/>
           <SecondSection/>
           <OurJoury/>
           <Testimonials/>
     
    </>
    
  )
}

export default Home