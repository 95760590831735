import React from 'react';
import "./impactgurusection1.scss"
import pic from "./images/MaskGroup50.png";
import dots from "./images/Group7693.svg";
import round from "./images/circle.png";
import hollow from "./images/hollow.png";
import solid from "./images/solid.png";
import ractangle from "./images/ractangle.png";
import group from "./images/groupsblack.png";
import { Container, Row, Col } from 'react-bootstrap';
import { Circle } from '@mui/icons-material';
const ImpactguruSection1 = () => {
    return (
        <div className='banner hb' >
        
        <div className='hb-overlay1 hb-overlay'></div>
        <Container className='hb-content'>
          <Row>
            <Col lg={8} className="hb-left">
              <div className='hb-left-header hb-left-header-sm' style={{color:"#fffff"}}></div>
              <div className='hb-left-header hb-left-header-main mt-2' style={{color:"#393939"}}>Crowdfund For All
              <br className='d-none d-md-block' />Critical Illnesses And <br/> Medical Treatments</div>
              <div>
                <a href='https://www.impactguru.com/' className='hb-btn1 hb-btn color-text' style={{color:"white !importan"}}>Know More </a>
              </div>
            </Col>
        
             <Col lg={4} className="hb-content-right m-t-mny">
                  <div className='hb-content-right-wp'>
                    <img src={pic} className='hb-content-img'   alt="" />
                    <img src={ractangle} className="shadow-back-main" />
                    <img src={solid} className="top-solid"/>
                    <img src={hollow} className="top-hollow"/>
                    <img src={round} className="top-circle"/>
                    <img className='dots-align' src={dots}   alt=""/>

                    <div className='hb-content-matrix1 hb-content-matrix '>
                      <img className='hb-content-matrix-img' src={group}  alt=""/>
                      <div className='hb-content-matrix-header1 hb-content-matrix-header' style={{color:"#fffff"}}>30+ Lakh</div>
                      <p className='hb-content-matrix-content1 hb-content-matrix-content' style={{color:"#fffff"}}>Donor Community</p>
                    </div>
                  </div>
                </Col>
          </Row>
        </Container>

      </div>
      )
  
}

export default ImpactguruSection1