import React from "react";
// import leftpic from "./images/Mask Group 175.png";
import leftpic from "./images/1.png";
import dots from "./images/Group 7693.svg";
import "./aboutsection2.scss";
import cat from "./images/Mask Group 185.png";
// import lady from "./images/Mask Group 176.png";
import lady from "./images/2.png";
import solid from "./images/solid.png";
import hollow from "./images/hollow.png";

import rectangle from "../../Pages/images/rectangle.png";

const AboutSection2 = () => {
  return (
    <section id="aboutTwo">
      <div className="container-fluid " style={{ marginTop: "6%" }}>
        <div className="row mb">
          <div className="col-10 mx-auto">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12 position-relative"
                style={{ height: "300px", backgroundColor: "#fffff" }}
              >
                <img className="right-pic-main-about" src={leftpic} alt="" />
                {/* <img className="left-pic-main-dots-about" src={dots} alt="" />
                <img src={rectangle} className="about-card-shadow" alt="" />
                <img src={solid} className="solidc" /> */}
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12 m-l-about "
                style={{  backgroundColor: "#fffff" }}
              >
                <div className="small-heading">About Us</div>
                <div className="paragrap-pic">
                  CarePal is an integrated player across the lending
                  marketplace, the health assurance marketplace and
                  crowdfunding. Our vision of providing an integrated healthcare
                  financing ecosystem that helps patients afford and access
                  quality healthcare services in India is reflective of the
                  solutions that we aim to provide to bridge the accessibility
                  gap in the healthcare system. Each of our businesses is an
                  extension of our values that focus on making critical and
                  elective treatments accessible and affordable to every patient
                  in India.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb">
          <div className="col-10 mx-auto">
            <div className="row reverse-in-mobile">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="small-heading">Vision And Values</div>
                <br />
                <div
                  className="small-heading-second"
                  style={{ textAlign: "left" }}
                >
                  <i>
                    To provide an integrated healthcare <br />
                    financing ecosystem that helps <br />
                    patients afford and access quality <br />
                    healthcare services in India.
                  </i>
                </div>
                <br />
                <div className="paragrap-pic">
                  Our story is foregrounded in the key belief that we want to
                  give people a platform through which they can ensure
                  accessibility and affordability of the best possible
                  healthcare. We envision an India, where everyone, independent
                  of their financial background, can avail quality secondary and
                  tertiary healthcare, especially in case of a medical emergency
                  or life-threatening illness. By holistically combining
                  healthcare financing approaches like Crowdfunding, Lending and
                  Health Assurance, CarePal Group aims to steadfastly move
                  forward towards achieving the goal of helping 1 million
                  patients by 2030.
                </div>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 postion-relative-block m-l-about-2 margin-t-t"
                style={{ height: "400px", backgroundColor: "#ffff" }}
              >
                  <img
                  className="left-pic-main-dots-about-lady"
                  src={lady}
                  alt=""
                />
                {/* <img
                  className="left-pic-main-dots-about-cat"
                  src={cat}
                  alt=""
                />
                 <img src={hollow} className="hollowa"/>
                 <img src={solid} className="solidb" /> */}
              
                {/* <img
                  className="right-pic-main-dots-about-belove"
                  src={dots}
                  alt=""
                />
                <img
                  className="right-pic-main-dots-about-above"
                  src={dots}
                  alt=""
                />
                <img src={rectangle} className="about-card-shadow2" alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection2;
