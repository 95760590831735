import React from 'react'
import "./ourjourny.css"
import pic from "./images/Group8320.png"
const OurJoury = () => {
  return (
    <div >
        <img className='mobile-viwe-ourjirny' src={pic} style={{width:"100%"}}  alt=""/>
    </div>
  )
}

export default OurJoury