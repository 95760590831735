import React from 'react'
import "./impactgurusection2.scss";
import { Container, Row, Col } from 'react-bootstrap';
import icone1 from "./images/icon1.png";
import icone2 from "./images/icone2.png";
import icone3 from "./images/icone3.png";


const Impactgurusection2 = () => {
    return (
        <div className='text ig-stats position-relative'>
            <div className='overlay-light-blue' ></div>
            <Container className='position-relative'>
                <Row>
                    <Col lg={12}>
                        <div className='d-flex justify-content-around ig-stats-wrapper' >
                            <div style={{paddingBottom:"20px",paddingTop:"20px"}}>
                                <img className='icone-image ms-1' src={icone1}  alt="" />
                                <div style={{ fontSize: "28px", fontStyle: "Roboto" ,color:"#ffff" }}>
                                    No Waiting Period
                                </div>
                                <div style={{ fontSize: "20px", fontStyle: "Roboto",color:"#ffff"  }}>
                                On Fundraiser
                                </div>

                            </div>
                            <div style={{paddingBottom:"20px",paddingTop:"20px"}}>
                                <img className='icone-image ms-1' src={icone2}  alt=""/>
                                <div style={{ fontSize: "28px", fontStyle: "Roboto" ,color:"#ffff" }}>
                                    24*7
                                </div>
                                <div style={{ fontSize: "20px", fontStyle: "Roboto" ,color:"#ffff"  }}>
                                    Expert Fundraising Support
                                </div>

                            </div>

                            <div style={{paddingBottom:"20px",paddingTop:"20px"}}>
                                <img className='icone-image ms-1' src={icone3}  alt=""/>
                                <div style={{ fontSize: "28px", fontStyle: "Roboto" ,color:"#ffff" }}>
                                    0%
                                </div>
                                <div style={{ fontSize: "20px", fontStyle: "Roboto",color:"#ffff"  }}>
                                    Platform Fee
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


        </div>

    )
}

export default Impactgurusection2