import React from "react";
// import rightpic from "./images/Mask Group 88.png";
import rightpic from "./images/1.png";
import dots from "./images/Group7693.svg";
// import leftpic from "./images/Mask Group 113.png";
import leftpic from "./images/2.png";
import "./impactgurusection3.scss";
import handicone from "./images/Group 8276.png";
import targeticon from "./images/Group 11276.png";
import rectangle from "../../Pages/images/rectangle.png";
import solid from "./images/solid.png";
import hollow from "./images/hollow.png";

const ImpactguruSection3 = () => {
  return (
    <section id="impsection3">
      <div
        className="container-fluid"
        style={{ paddingTop: "20px", paddingBottom: "40px" }}
      >
        <div className="container">
          <div className="row reverse-in-mobile">
            <div
              className="col-lg-7 col-md-8 col-sm-12 sectow-card-content container-height-im "
              style={{ backgroundColor: "#fffff" }}
            >
              <div className="maincontaint-heading-bolg revers-text-box">
                <img
                  className="width-icone-size px-24"
                  src={handicone}
                  alt=""
                />
                &nbsp;Putting Health First
              </div>
              <div className="maincontaint-paragrap-bolg revers-text-box">
                We are creating innovative technology and finance solutions to
                empower people to <br />
                raise funds for medical emergencies, critical illnesses and even
                rare diseases like <b />
                Spinal Muscular Atrophy <strong> (SMA)</strong> that require
                treatment ranging as high as <strong>INR 16 Crores</strong> .
                <br />
                Our platform has empowered lakhs of people to transform lives
                through crowdfunding.
              </div>
            </div>
            <div
              className="col-lg-5 col-md-4 col-sm-12 postion-relative-block container-height-im m-l-impact"
              style={{ backgroundColor: "#fffff" }}
            >
              <img className="right-pic-main" src={rightpic} alt="" />
              {/* <img className="right-pic-main-dots" src={dots} alt="" />
                <img className="card-shadow-imp" src={rectangle} alt="" />
                <img src={hollow} className="hollow-a"/>
              <img src={solid} className="solid-b" /> */}
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-6 col-md-5 col-sm-12 position-relative container-height-im2 m-r-impact"
              style={{ backgroundColor: "#fffff" }}
            >
              <img className="left-pic-main" src={leftpic} alt="" />
              {/* <img className="left-pic-main-dots" src={dots} alt="" />
                <img className="card-shadow-imp2" src={rectangle} alt="" />
                
              <img src={solid} className="solid-a" /> */}
            </div>
            <div
              className="col-lg-6 col-md-7 col-sm-12 sectow-card-content"
              style={{ height: "400px", backgroundColor: "#fffff" }}
            >
              <div className="maincontaint-heading-bolg revers-text-box">
                <img
                  className="width-icone-size px-24"
                  src={targeticon}
                  alt=""
                />
                &nbsp;Our Mission
              </div>
              <div
                className="maincontaint-paragrap-bolg revers-text-box"
                style={{ fontSize: "20px" }}
              >
                <strong>
                  <i>
                    Make Healthcare Affordable To Save Lives Today,
                    <br /> While Securing Families For A Better Tomorrow.
                  </i>
                </strong>
              </div>{" "}
              <br />
              <div className="maincontaint-paragrap-bolg revers-text-box">
                With donation-based crowdfunding on ImpactGuru, getting
                financial help for patients in India is convenient and reliable.
                Unlike medical loans and insurance, fundraising via online
                crowdfunding platforms requires no liability to pay back the
                funds raised. So, all the amount raised can be used to cover the
                cost of emergency medical treatment.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImpactguruSection3;
