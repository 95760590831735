import {React,useState} from 'react';
import piyushpic from "./images/Group 8268.png";
import khushboo from "./images/Group 8267.png";
import vikas from "./images/Group 8266.png";

import "./leader.scss"

const LeaderShip = () => {
      const [firstcontent,setFirstcontent]=useState(false)
      const [secondcontent ,setSecondcontent]=useState(false)
      const [thirdcontent,setThirdcontent]=useState(false)
  return (

    <div className='container m-t-leader m-top-l'>
       <div className='small-heading-second' style={{textAlign:"center"}}>Leadership Team</div>
        <div className='row leader-border m-t-in-fl' style={{textAlighn:"center",marginTop:"40px"}}>
              <div className='col-lg-3 col-md-3 col-sm-12 text-center m-t-first'>
                   <img src={piyushpic} style={{width:"200px",height:"200px",borderRadius:"140px"}}  alt=""/>
              </div>
              <div className='col-lg-9 col-md-9 col-sm-12  '>
                 <div className='small-heading-leader '>Piyush Jain</div><br/>
                 <div className='small-heading-second-leader'>Co-Founder & CEO, CarePal Group</div>
                 <br/>
                   <div className='leader-font-size'>
                        <div>Piyush Jain is the Co-Founder and Chief Executive Officer of CarePal Group, India’s integrated healthcare financing ecosystem which comprises of ImpactGuru.com, India's leading medical crowdfunding platform, CarePal Secure, a prominent health protection and insurance marketplace and CarePal Money, a medical lending marketplace.
                        </div><br/>
                        {firstcontent ? <div>
                             
                              <p>Piyush currently leads a team of over 800 employees and transformed a business plan prepared as a student into an actual business, and got the venture incubated at Harvard Innovation Lab. The business has scaled tremendously by impacting thousands of patients' lives and their families in India.</p>
                              <p>Piyush has an undergraduate degree from Wharton Business School, University of Pennsylvania, where he was a Joseph Wharton Scholar and a graduate degree in Public Policy from Harvard University. At Harvard, he assisted Professor Thornburg in teaching a class on Financial Management in Nonprofit Organizations. He has co-authored a paper at Harvard Business School on innovative ways to finance entrepreneurial and social ventures. Piyush was previously an M&A investment banker with JP Morgan (New York, Hong Kong, and London), and a Management Consultant with BCG (Southeast Asia).</p>
                              <p>Piyush was featured in the Fortune 40 under 40 and BW Healthcare 40 under 40 list in India and was felicitated with the Social Entrepreneur of the Year Award by Entrepreneur India magazine. Piyush was part of the 2020 cohort of the Stanford Seed Transformation Program as well as the 2020 cohort of  The Global Good Fund Fellowship Program.</p>
                        </div>:null}
                   </div>
              </div>
              <div className='anchor-tag-read read-m-l'>
                  {firstcontent ?   <a style={{color:"#1E75BB",cursor:"pointer"}} onClick={()=>{setFirstcontent(!firstcontent)}}>Read Less ▲</a>:  <a style={{color:"#1E75BB" ,cursor:"pointer"}} onClick={()=>{setFirstcontent(!firstcontent)}}>Read More ▼</a>}
              
              </div>
            
        </div>
          
        <div className='row leader-border'>
              <div className='col-lg-3 col-mg-3 col-sm-12 text-center'>
              <img src={vikas} style={{width:"200px",height:"200px",borderRadius:"140px"}}  alt=""/>
              </div>
              <div className='col-lg-9 col-mg-9 col-sm-12'>
                 <div className='small-heading-leader '>Khushboo Jain</div><br/>
                 <div className='small-heading-second-leader'>Co-Founder & COO, CarePal Group</div>
                 <br/>
                   <div className='leader-font-size'>
                        <p>Khushboo Jain is the Co-founder and the Chief Operating Officer of CarePal Group. Khushboo Jain is an alumna of Sydenham College, Mumbai, where she studied Business Management, and WE Business School – Welingkar Institute of Management Development & Research, where she earned an MBA with a focus on Marketing. She went on to study fashion at the London College of Fashion and at Parsons, the New School of Design in New York, coalescing insights
                               and experience across fashion retail, branding, marketing, import and export.</p><br/>
                               {secondcontent?<div>
                                    <p>Khushboo Jain has worked as a fashion marketer for Valiram in Singapore, handling a brand portfolio that includes Hackett, La Martina and Jimmy Choo, liaising closely with the press. She has also worked with celebrity Indian designer Manish Malhotra in a fashion merchandising and buying role, and at another with
                                           actor/model/designer Malaika Arora Khan’s The Closet Label (now The Label Life).  </p>   <br/>
                                           <p>Marking her remarkable presence in the entrepreneurial and philanthropic sector, Khushboo Jain was
                                                 featured in the 2021 list of BW healthcare World 40 under 40. She was felicitated as a social activist for indefatigable service during the Covid-19 crisis by the Punjab Govt. Dept of Health & Family Welfare. She was also featured in the winning top 15
                                                  by NITI Aayog & United Nations - 2019 Women Transforming India Awards.</p><br/>
                                    <p>Khushboo believes that like every brand, every individual has a story. Her plethora of experience in brand-building, coupled with her commitment to philanthropy drives the Co-founder of CarePal Group to work towards ensuring that each voice is heard - even amplified - and that every single patient in India has the chance to access quality healthcare.</p>

                               </div>:null}
                   </div>
              </div>
              <div className='anchor-tag-read read-m-l'>
                  {secondcontent ?<a style={{color:"#1E75BB",cursor:"pointer"}} onClick={()=>{setSecondcontent(!secondcontent)}}>Read Less ▲</a>:<a style={{color:"#1E75BB",cursor:"pointer"}} onClick={()=>{setSecondcontent(!secondcontent)}}>Read More ▼</a>}
              
              </div>
        </div>
        <div className='row leader-border'>
              <div className='col-lg-3 col-mg-3 col-sm-12 text-center'>
                   <img src={khushboo} style={{width:"200px",height:"200px",borderRadius:"140px"}}  alt=""/>
              </div>
              <div className='col-lg-9 col-mg-9 col-sm-12'>
                 <div className='small-heading-leader '>Vikas Kaul</div><br/>
                 <div className='small-heading-second-leader'>Founding Member, CarePal Group</div>
                 <br/>
                 <div className='leader-font-size'>
                   <p>An engineer turned entrepreneur, Vikas Kaul is a firm believer in
                         the power of technology to accelerate the progress of any organisation he associates with. He has a graduate degree in Computer Science Engineering and an MBA in Finance & Strategy from IIM Kozhikode. Vikas has more than a decade of experience, working in the software sector and several years of experience in
                         entrepreneurship with a strong focus on the product and technology side of the business. </p>
                         {thirdcontent?<div>
                              <p>
                               Vikas has worked with well-known companies like Quikr and Deloitte contributing to technological advancement and evaluating new ventures for investment opportunities. He even cofounded Meldoc, a doctors-only platform that aims to provide end-to-end reliable services to medical professionals across India.  
                               </p>
                               <br/>
                               <p>As the founding member of CarePal Group, Vikas Kaul aims to use his entrepreneurial experience and technological skills for product innovations and to build strategies for the holistic growth of CarePal.
</p>
                         </div>:null}
                 </div>
              </div>
              <div className='anchor-tag-read read-m-l'>
              {thirdcontent ?   <a style={{color:"#1E75BB",cursor:"pointer"}} onClick={()=>{setThirdcontent(!thirdcontent)}} >Read Less ▲</a>:  <a style={{color:"#1E75BB" ,cursor:"pointer"}} onClick={()=>{setThirdcontent(!thirdcontent)}}>Read More ▼</a>}
               </div>
        </div>
        <div>
       
        </div>
    </div>
  )
}

export default LeaderShip